import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Question from '../components/Question';
import JSONData from "../data/quiz/gaming.json";

const GamingQuiz = props => {
  const { data } = props.data;

  const title = "Gaming-quiz: 50 spørsmål og svar";

  return (
    <Layout showRelated={true}>
      <SEO
          description="Er du en gaming-nerd? Prøv vår gaming-quiz!. Mange andre quizer er tilgjengelig gratis på quizbladet.no."
        title={title} />
      <div className="quiz">
          {<div className="justify-content-start pb-2">
            <div className="pt-6">
              <div dangerouslySetInnerHTML={{ __html: data.html }} />
            </div>
          </div>}
          <div>
            {JSONData && JSONData.map((it, index) => {
              return (
                <Question addBtn={true} index={index+1} data={it}/>
            )})}
          </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    data: markdownRemark(fileAbsolutePath: {regex: "/(Gaming.md)/"}) {
      html
    }
  }
`;

export default GamingQuiz;
